<template>
  <div class="whpcTile">
    <router-link
      class="link"
      :to="'/stories?id=' + story.id"
      @click.native="setSelectedStory"
    >
      <AssetDownload
        @click.native="setSelectedStory"
        :class="height"
        :asset="story.thumbnailAsset"
        :forceImage="storyImage"
        :isStoryTile="true">
      </AssetDownload>
    </router-link>
    <div class="middleCopy">
      <router-link
        class="link"
        :to="'/stories?id=' + story.id"
        @click.native="setSelectedStory"
      >
        <div class="title">{{story.subject}}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import AssetDownload from '../../../asset/AssetDownload.vue';

export default {
  name: 'WhpcStoryTile',
  props: ['story', 'givenHeightName', 'storyImage'],
  components: {
    AssetDownload,
  },
  data() {
    return {
      downloadedImage: null,
    };
  },
  methods: {
    setSelectedStory() {
      this.$store.dispatch(
        'whpcStore/setCurrentStory',
        this.story,
      );

      this.$analytics.trackEventBtnPageWithMetaInfo('WHPCStoryTileClick',
        { whpcStoryId: this.story.id });
    },
  },
  computed: {
    height() {
      if (this.givenHeightName) {
        return this.givenHeightName;
      }

      const min = 1;
      const max = 3;
      const random = Math.floor(Math.random() * (+max - +min)) + +min;
      switch (random) {
        case 1:
          return 'sizeFull';
        case 2:
          return 'sizeMedium';
        default:
          return 'sizeSmall';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.whpcTile {
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  color: $BrandStoryTileText;
  overflow: hidden;

    .sizeFull {
      height: 300px;
    }

    .sizeMedium {
      height: 200px;
    }

    .sizeSmall {
      height: 150px;
    }

  .middleCopy {
    padding: 10px 10px 15px 10px;

    .title {
      @include font-size(1.125rem);
    }

    a {
      text-decoration: none;
      color: inherit;

      &:hover {
        color: $LincolnOrange;
      }
    }
  }
}
</style>
