<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <span v-for="actionName in activeButtons" :key="actionName">
          <button v-if="actionName === 'bold'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.bold() }"
                  @click.prevent="commands.bold">
            <icon name="bold" />
          </button>
          <button v-if="actionName === 'italic'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.italic() }"
                  @click.prevent="commands.italic">
            <icon name="italic" />
          </button>
          <button v-if="actionName === 'strike'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.strike() }"
                  @click.prevent="commands.strike">
            <icon name="strike" />
          </button>
          <button v-if="actionName === 'underline'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.underline() }"
                  @click.prevent="commands.underline">
            <icon name="underline" />
          </button>
          <button v-if="actionName === 'code'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.code() }"
                  @click.prevent="commands.code">
            <icon name="code" />
          </button>
          <button v-if="actionName === 'paragraph'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.paragraph() }"
                  @click.prevent="commands.paragraph">
            <icon name="paragraph" />
          </button>
          <button v-if="actionName === 'h1'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                  @click.prevent="commands.heading({ level: 1 })">
            H1
          </button>
          <button v-if="actionName === 'h2'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                  @click.prevent="commands.heading({ level: 2 })">
            H2
          </button>
          <button v-if="actionName === 'h3'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                  @click.prevent="commands.heading({ level: 3 })">
            H3
          </button>
          <button v-if="actionName === 'bullet_list'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.bullet_list() }"
                  @click.prevent="commands.bullet_list">
            <icon name="ul" />
          </button>
          <button v-if="actionName === 'ordered_list'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.ordered_list() }"
                  @click.prevent="commands.ordered_list">
            <icon name="ol" />
          </button>
          <button v-if="actionName === 'blockquote'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.blockquote() }"
                  @click.prevent="commands.blockquote">
            <icon name="quote" />
          </button>
          <button v-if="actionName === 'code_block'"
                  class="menubar__button"
                  :class="{ 'is-active': isActive.code_block() }"
                  @click.prevent="commands.code_block">
            <icon name="code" />
          </button>
          <button v-if="actionName === 'horizontal_rule'"
                  class="menubar__button"
                  @click.prevent="commands.horizontal_rule">
            <icon name="hr" />
          </button>
          <button v-if="actionName === 'undo'"
                  class="menubar__button"
                  @click.prevent="commands.undo">
            <icon name="undo" />
          </button>
          <button v-if="actionName === 'redo'"
                  class="menubar__button"
                  @click.prevent="commands.redo">
            <icon name="redo" />
          </button>
        </span>
      </div>
    </editor-menu-bar>
    <editor-content class="editor__content" :editor="editor" />
    <input :name="name" :value="html" style="display: none" />
  </div>
</template>
<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions';
import Icon from './RichTextBoxIcon.vue';

export default {
  name: 'RichTextBox',
  components: {
    EditorContent,
    EditorMenuBar,
    Icon,
  },
  props: {
    name: {
      type: String,
    },
    initialContent: {
      type: String,
      default: '',
    },
    activeButtons: {
      type: Array,
      validator(list) {
        const allowed = [
          'bold',
          'italic',
          'strike',
          'underline',
          'code',
          'paragraph',
          'h1',
          'h2',
          'h3',
          'bullet_list',
          'ordered_list',
          'blockquote',
          'code_block',
          'horizontal_rule',
          'undo',
          'redo',
        ];

        if (list.some(el => allowed.indexOf(el) === -1)) {
          return -1;
        }

        return 1;
      },
      default() {
        return [
          'bold',
          'italic',
          'strike',
          'underline',
          'code',
          'paragraph',
          'h1',
          'h2',
          'h3',
          'bullet_list',
          'ordered_list',
          'blockquote',
          'code_block',
          'horizontal_rule',
          'undo',
          'redo',
        ];
      },
    },
  },
  data() {
    return {
      html: '',
      json: '',
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
        content: this.initialContent,
      }),
    };
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  created() {
    this.html = this.editor.getHTML();
    this.json = this.editor.getJSON();

    this.editor.on('update', () => {
      this.html = this.editor.getHTML();
      this.json = this.editor.getJSON();
      this.$emit('update', this.html);
    });
  },
};
</script>
<style lang="css" scoped>

</style>
