var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "icon",
      class: [
        "icon--" + _vm.name,
        "icon--" + _vm.size,
        { "has-align-fix": _vm.fixAlign },
      ],
    },
    [
      _c("img", {
        attrs: {
          src: require("@/assets/images/icons/" + _vm.name + ".svg"),
          alt: "" + _vm.name,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }