var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "imgPreviewContainer" },
    [
      _c(
        "masonry",
        {
          key: _vm.key,
          staticClass: "imgPreviewGrid",
          attrs: {
            cols: { default: 3, 600: 2, 768: 3, 992: 4, 1200: 5 },
            gutter: { default: "15px" },
          },
        },
        [
          _c(
            "div",
            { staticClass: "titlePreview" },
            [
              _vm._v("\n      Full View\n      "),
              _c("BrandStoryTile", {
                attrs: {
                  brandStory: _vm.story,
                  brandStoryImage: _vm.cropImg,
                  givenHeightName: "sizeFull",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "titlePreview" },
            [
              _vm._v("\n      Medium View\n      "),
              _c("BrandStoryTile", {
                attrs: {
                  brandStory: _vm.story,
                  brandStoryImage: _vm.cropImg,
                  givenHeightName: "sizeMedium",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "titlePreview" },
            [
              _vm._v("\n      Small View\n      "),
              _c("BrandStoryTile", {
                attrs: {
                  brandStory: _vm.story,
                  brandStoryImage: _vm.cropImg,
                  givenHeightName: "sizeSmall",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }