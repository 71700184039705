<template>
    <div class="imgPreviewContainer">
      <div class="titlePreview">
        Full View
        <WhpcTile
          :story="story"
          :storyImage="cropImg"
          :givenHeightName="'sizeFull'"/>
      </div>
      <div class="titlePreview">
        Medium View
        <WhpcTile
          :story="story"
          :storyImage="cropImg"
          :givenHeightName="'sizeMedium'"/>
      </div>
     <div class="titlePreview">
        Small View
        <WhpcTile
          :story="story"
          :storyImage="cropImg"
          :givenHeightName="'sizeSmall'"/>
      </div>
    </div>
</template>

<script>
import WhpcTile from '../stories/whpc/list/WhpcTile.vue';

export default {
  name: 'ImagePreviewerWHPC',
  props: ['story', 'cropImg'],
  components: {
    WhpcTile,
  },
};
</script>

<style lang="scss" scoped>
.imgPreviewContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.imgPreviewContainer > * {
    padding: 10px;
    width: 250px;
}
</style>
