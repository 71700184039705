var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c("editor-menu-bar", {
        attrs: { editor: _vm.editor },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var commands = ref.commands
              var isActive = ref.isActive
              return [
                _c(
                  "div",
                  { staticClass: "menubar" },
                  _vm._l(_vm.activeButtons, function (actionName) {
                    return _c("span", { key: actionName }, [
                      actionName === "bold"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.bold() },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.bold($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "bold" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "italic"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.italic() },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.italic($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "italic" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "strike"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.strike() },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.strike($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "strike" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "underline"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.underline() },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.underline($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "underline" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "code"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.code() },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.code($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "code" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "paragraph"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.paragraph() },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.paragraph($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "paragraph" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "h1"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: {
                                "is-active": isActive.heading({ level: 1 }),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.heading({ level: 1 })
                                },
                              },
                            },
                            [_vm._v("\n          H1\n        ")]
                          )
                        : _vm._e(),
                      actionName === "h2"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: {
                                "is-active": isActive.heading({ level: 2 }),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.heading({ level: 2 })
                                },
                              },
                            },
                            [_vm._v("\n          H2\n        ")]
                          )
                        : _vm._e(),
                      actionName === "h3"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: {
                                "is-active": isActive.heading({ level: 3 }),
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.heading({ level: 3 })
                                },
                              },
                            },
                            [_vm._v("\n          H3\n        ")]
                          )
                        : _vm._e(),
                      actionName === "bullet_list"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.bullet_list() },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.bullet_list($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "ul" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "ordered_list"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.ordered_list() },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.ordered_list($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "ol" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "blockquote"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.blockquote() },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.blockquote($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "quote" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "code_block"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              class: { "is-active": isActive.code_block() },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.code_block($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "code" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "horizontal_rule"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.horizontal_rule($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "hr" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "undo"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.undo($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "undo" } })],
                            1
                          )
                        : _vm._e(),
                      actionName === "redo"
                        ? _c(
                            "button",
                            {
                              staticClass: "menubar__button",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return commands.redo($event)
                                },
                              },
                            },
                            [_c("icon", { attrs: { name: "redo" } })],
                            1
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]
            },
          },
        ]),
      }),
      _c("editor-content", {
        staticClass: "editor__content",
        attrs: { editor: _vm.editor },
      }),
      _c("input", {
        staticStyle: { display: "none" },
        attrs: { name: _vm.name },
        domProps: { value: _vm.html },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }