<template>
  <div class="imgPreviewContainer">
    <masonry :cols="{default: 3, 600: 2, 768: 3, 992: 4, 1200: 5}"
             :gutter="{default: '15px'}"
             :key="key"
             class="imgPreviewGrid">
      <div class="titlePreview">
        Full View
        <BrandStoryTile
          :brandStory="story"
          :brandStoryImage="cropImg"
          :givenHeightName="'sizeFull'"/>
      </div>
      <div class="titlePreview">
        Medium View
        <BrandStoryTile
          :brandStory="story"
          :brandStoryImage="cropImg"
          :givenHeightName="'sizeMedium'"/>
      </div>
      <div class="titlePreview">
        Small View
        <BrandStoryTile
          :brandStory="story"
          :brandStoryImage="cropImg"
          :givenHeightName="'sizeSmall'"/>
      </div>
    </masonry>
  </div>
</template>

<script>
import BrandStoryTile from '../stories/brandStories/list/BrandStoryTile.vue';

export default {
  name: 'ImagePreviewerBrandStory',
  props: ['story', 'cropImg'],
  components: {
    BrandStoryTile,
  },
};
</script>

<style lang="scss" scoped>
.imgPreviewContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .imgPreviewGrid {
      width: 66%;
    }
}

.imgPreviewContainer > * {
    padding: 10px;
}
</style>
